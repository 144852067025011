body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    background-color: #f7f7f7;
}

@media only screen and (max-width: 720px) {
    body {
        background-color: #fff;
    }
}
.guest-inbox-app__message {
    display: flex;
    flex-direction: column;
    height: 100%;
    }

.guest-inbox-app__message-header {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    background-color: white;
}

.guest-inbox-app__message-list-wrap {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.guest-inbox-app__message-list {
    padding: 0 16px;
    position: relative;
    flex-grow: 1;
    overflow: auto;
    height: 100%;
    background: #fafafa
}

.guest-inbox-app__message-input {
    padding: 8px 0;
    bottom: 0;
    position: sticky;
    background-color: #fafafa
}

.conversation-message-input {
    display: flex;
    align-items: flex-end;
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
}

.conversation-message-input__content {
    flex-grow: 1;
}

.message-content-input-label {
    display: none !important;
}

.message-content-input {
    margin-top: 0 !important;
    min-height: 48px;
    box-sizing: border-box;
}

.guest-inbox-app__message-list-item {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
}

.guest-inbox-app__message-list-item.patient {
    flex-direction: row-reverse;
}

.guest-inbox-app__message-list-item__message {
    max-width: 85%;
}

.guest-inbox-app__message-list-item__message__content__container {
    display: flex;
}

.guest-inbox-app__message-list-item__message__content {
    display: inline-block;
    font-size: 13px;
    padding: 8px;
    border-radius: 4px;
    background-color: #f3f3f3;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.15);    
    white-space: pre-line;
    word-break: break-word;
    color: #2a2a2a;
}

.guest-inbox-app__message-list-item.patient .guest-inbox-app__message-list-item__message__content__container {
    flex-direction: row-reverse;
}

.guest-inbox-app__message-list-item.patient .guest-inbox-app__message-list-item__message__content {
    background-color: #E8F0FE;
}

.guest-inbox-app__message-list-item__message__time {
    font-size: 10px;
    color: rgb(74,74,74);
    text-align: left;
}

.guest-inbox-app__message-list-item.patient .guest-inbox-app__message-list-item__message__time {
    text-align: right;
}

.guest-inbox-app__message-list-item__time {
    font-size: 10px;
    color: rgb(74,74,74);
    text-align: center;
    margin-top: 16px;
    margin-bottom: 8px;
}

.conversation-message__warning {
    font-size: 10px;
    color: #9e9e9e;
    text-align: center;
    padding: 0 8px;
    margin: 0 0 8px;
}

.conversation-message-cta__container {
    display: block;
    width: 100%;
    max-width: 343px;
    text-decoration: none;
    color: #2a2a2a;
}

.conversation-message-cta__bubble {
    background-color: #f3f3f3;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.15);
    border-radius: 4px;
    padding: 16px;
    padding-right: 8px;
    -webkit-user-select: none;
            user-select: none;
    color: #2a2a2a;
}


.inline-question-response {
    box-shadow: 1px 1px 2px rgba(0,0,0,0.1);  
    border-radius: 4px;
    font-size: 14px;
    padding: 8px;
    position: absolute;
    margin-top: 5px;
    right: 16px;
    color: #555;
}

.prompt-wrapper {
    padding: 5px 10px;
    border-radius: 4px    
}

.prompt-wrapper .option-button {
    min-width: 80px;
}

.prompt-wrapper .response-note {
    font-size: 11px;
    margin-top: 5px;
}

.inline-question-response .value {
    font-weight: 500;
    color: #000000;
}


.guest-inbox-app__message-list-item.patient .conversation-message-cta__bubble {
    background-color: #e8f0fe;
}

.assessment-cta-preprompt, .assessment-cta-response {
    font-size: 14px;
    margin-bottom: 10px;
    word-break: break-word;
    white-space: pre-line;
}



.conversation-message-cta__content {
    display: flex;
}

.conversation-message-cta__text {
    flex: 1 1;
    padding: 0 10px;
}

.conversation-message-cta__text h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 4px;
}

.conversation-message-cta__text p {
    font-size: 12px;
    line-height: 1.1;
}

.guest-inbox-app__message-list-item__cta-wrapper {
    display: flex;
    margin-top: 16px;
}

.guest-inbox-app__message-list-item.patient .guest-inbox-app__message-list-item__cta-wrapper {
    justify-content: flex-end;
}
.guest-inbox-app {
    height: 90vh;
    max-height: 650px;
    width: 100%;
    max-width: 720px;
    margin: 32px auto;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,.15);
    overflow: auto;
    position: relative;
}

.header-nav-title-text {
    flex-grow: 1;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.overlay-circular-progress {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.8);
    z-index: 10;
    width: 100%;
    height: 100%;
}

.overlay-circular-progress.show {
    display: block;
}

.overlay-circular-progress-child {
    position: fixed;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    /*background-color: #fff;*/
    /*padding: 8px;*/
    /*border-radius: 100%;*/
}

.nav-icon-padding {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.left-nav-icon-padding {
    margin-left: 4px !important;
}

.right-nav-icon-padding {
    margin-right: 4px !important;
}

.left-nav-text-padding {
    margin-left: 16px !important;
}

@media only screen and (max-width: 720px) {
    .guest-inbox-app {
        height: 100vh;
        max-height: 100vh;
        margin: 0;
        border: none;
        border-radius: 0;
        background-color: #fff;
        box-shadow: none;
    }
}

.user-login {
    padding: 32px;
    /* margin-top: 64px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.user-login__form {
    flex-grow: 1;
}

.user-login__pharmacy {
    text-align: center;
    font-size: 32px;
    margin-bottom: 32px;
}

.user-login__form-helper {
    font-size: 14px;
    text-align: center;
    color: rgb(74, 74, 74);
}

.user-login__form-submit-wrap {
    text-align: right;
    margin-top: 24px;
}

.user-login__pharmacy-phone {
    margin-top: 32px;
    text-align: center;
}

.frequently-asked-questions-outer {
    background: rgba(0,0,0,0.65);
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.frequently-asked-questions-modal {
    background: #fff;
    margin: 10px;
    height: calc(100% - 20px);
}

.frequently-asked-questions-modal .heading {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 0 10px;
    width: calc(100% - 20px);
    align-items: center;
    font-weight: 500;
    font-size: 17px
}

.frequently-asked-questions-modal .question-and-answer {
    margin: 30px 0;
    padding: 0 10px;
}

.frequently-asked-questions-modal .question-and-answer .question {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 24px;
}

.frequently-asked-questions-modal .question-and-answer .answer {
    font-size: 14px;
    line-height: 24px;
}

.guest-inbox-app__conversation-header {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    height: 100%;
}

.guest-inbox-app__conversation-list-wrap {
    flex-grow: 1;
}

.MuiToolbar-root {
    border-bottom: 1px solid #e6e6e6;
    background: #fff;
}

.conversation-list-item .MuiListItemText-root {
    padding: 0px 0px 0px 10px;
    color: #444;
}

.guest-inbox-app__conversation-list {
    position: relative;
    height: 100%;
    overflow: auto;
}

.conversation-list-item {
    border-bottom: 1px solid #eee !important;
} 

.conversation-list-item__primary {
    display: flex;
    align-items: center;
}

.conversation-list-item__primary-subject {
    flex-grow: 1;
}

.conversation-list-item .conversation-list-item__primary-date {
    font-size: 10px;
}

.conversation-list-item .conversation-list-item__secondary-text {
    font-size: 12px;
    font-weight: 400;
    color: #9b9b9b;
    display: block;
}

.conversation-list-item.unread .MuiListItemText-root {
    color: #000;
}

.conversation-list-item.unread .conversation-list-item__primary-subject {
    font-weight: 500;
}

.conversation-list-item.unread .conversation-list-item__primary-date {
    font-weight: 600;
}

.conversation-list-item.unread .conversation-list-item__secondary-text {

}

.inbox-activity-avatar-badge {
    padding: 0;
    height: 12px !important;
    min-width: 12px !important;
    right: 6px !important;
    top: 6px !important;
    border-radius: 10px !important;
    background-color: #E87A80 !important;
}
.img-thumbnail {
    max-height: 150px;
    max-width: 150px;
    padding: 10px;
    border: 1px dashed #999;
    margin-bottom: 15px;
}

 .remove svg:hover {
    -webkit-filter: drop-shadow(1.5px 1.5px 1.5px);
            filter: drop-shadow(1.5px 1.5px 1.5px)
  }

 .remove svg {
    -webkit-filter: drop-shadow(1px 1px 1px #999);
            filter: drop-shadow(1px 1px 1px #999);
    transition: 0.3s;
    -webkit-transform: rotate(-0.2deg);
            transform: rotate(-0.2deg)
  } 

  .remove {
    position: relative;
    top: 22px;
    right: 22px;
  }


