.img-thumbnail {
    max-height: 150px;
    max-width: 150px;
    padding: 10px;
    border: 1px dashed #999;
    margin-bottom: 15px;
}

 .remove svg:hover {
    filter: drop-shadow(1.5px 1.5px 1.5px)
  }

 .remove svg {
    filter: drop-shadow(1px 1px 1px #999);
    transition: 0.3s;
    transform: rotate(-0.2deg)
  } 

  .remove {
    position: relative;
    top: 22px;
    right: 22px;
  }

