.user-login {
    padding: 32px;
    /* margin-top: 64px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.user-login__form {
    flex-grow: 1;
}

.user-login__pharmacy {
    text-align: center;
    font-size: 32px;
    margin-bottom: 32px;
}

.user-login__form-helper {
    font-size: 14px;
    text-align: center;
    color: rgb(74, 74, 74);
}

.user-login__form-submit-wrap {
    text-align: right;
    margin-top: 24px;
}

.user-login__pharmacy-phone {
    margin-top: 32px;
    text-align: center;
}

.frequently-asked-questions-outer {
    background: rgba(0,0,0,0.65);
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.frequently-asked-questions-modal {
    background: #fff;
    margin: 10px;
    height: calc(100% - 20px);
}

.frequently-asked-questions-modal .heading {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 0 10px;
    width: calc(100% - 20px);
    align-items: center;
    font-weight: 500;
    font-size: 17px
}

.frequently-asked-questions-modal .question-and-answer {
    margin: 30px 0;
    padding: 0 10px;
}

.frequently-asked-questions-modal .question-and-answer .question {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 24px;
}

.frequently-asked-questions-modal .question-and-answer .answer {
    font-size: 14px;
    line-height: 24px;
}