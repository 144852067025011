.guest-inbox-app {
    height: 90vh;
    max-height: 650px;
    width: 100%;
    max-width: 720px;
    margin: 32px auto;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,.15);
    overflow: auto;
    position: relative;
}

.header-nav-title-text {
    flex-grow: 1;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.overlay-circular-progress {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.8);
    z-index: 10;
    width: 100%;
    height: 100%;
}

.overlay-circular-progress.show {
    display: block;
}

.overlay-circular-progress-child {
    position: fixed;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    /*background-color: #fff;*/
    /*padding: 8px;*/
    /*border-radius: 100%;*/
}

.nav-icon-padding {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.left-nav-icon-padding {
    margin-left: 4px !important;
}

.right-nav-icon-padding {
    margin-right: 4px !important;
}

.left-nav-text-padding {
    margin-left: 16px !important;
}

@media only screen and (max-width: 720px) {
    .guest-inbox-app {
        height: 100vh;
        max-height: 100vh;
        margin: 0;
        border: none;
        border-radius: 0;
        background-color: #fff;
        box-shadow: none;
    }
}
