.guest-inbox-app__message {
    display: flex;
    flex-direction: column;
    height: 100%;
    }

.guest-inbox-app__message-header {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    background-color: white;
}

.guest-inbox-app__message-list-wrap {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.guest-inbox-app__message-list {
    padding: 0 16px;
    position: relative;
    flex-grow: 1;
    overflow: auto;
    height: 100%;
    background: #fafafa
}

.guest-inbox-app__message-input {
    padding: 8px 0;
    bottom: 0;
    position: sticky;
    background-color: #fafafa
}

.conversation-message-input {
    display: flex;
    align-items: flex-end;
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
}

.conversation-message-input__content {
    flex-grow: 1;
}

.message-content-input-label {
    display: none !important;
}

.message-content-input {
    margin-top: 0 !important;
    min-height: 48px;
    box-sizing: border-box;
}

.guest-inbox-app__message-list-item {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
}

.guest-inbox-app__message-list-item.patient {
    flex-direction: row-reverse;
}

.guest-inbox-app__message-list-item__message {
    max-width: 85%;
}

.guest-inbox-app__message-list-item__message__content__container {
    display: flex;
}

.guest-inbox-app__message-list-item__message__content {
    display: inline-block;
    font-size: 13px;
    padding: 8px;
    border-radius: 4px;
    background-color: #f3f3f3;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.15);    
    white-space: pre-line;
    word-break: break-word;
    color: #2a2a2a;
}

.guest-inbox-app__message-list-item.patient .guest-inbox-app__message-list-item__message__content__container {
    flex-direction: row-reverse;
}

.guest-inbox-app__message-list-item.patient .guest-inbox-app__message-list-item__message__content {
    background-color: #E8F0FE;
}

.guest-inbox-app__message-list-item__message__time {
    font-size: 10px;
    color: rgb(74,74,74);
    text-align: left;
}

.guest-inbox-app__message-list-item.patient .guest-inbox-app__message-list-item__message__time {
    text-align: right;
}

.guest-inbox-app__message-list-item__time {
    font-size: 10px;
    color: rgb(74,74,74);
    text-align: center;
    margin-top: 16px;
    margin-bottom: 8px;
}

.conversation-message__warning {
    font-size: 10px;
    color: #9e9e9e;
    text-align: center;
    padding: 0 8px;
    margin: 0 0 8px;
}

.conversation-message-cta__container {
    display: block;
    width: 100%;
    max-width: 343px;
    text-decoration: none;
    color: #2a2a2a;
}

.conversation-message-cta__bubble {
    background-color: #f3f3f3;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.15);
    border-radius: 4px;
    padding: 16px;
    padding-right: 8px;
    user-select: none;
    color: #2a2a2a;
}


.inline-question-response {
    box-shadow: 1px 1px 2px rgba(0,0,0,0.1);  
    border-radius: 4px;
    font-size: 14px;
    padding: 8px;
    position: absolute;
    margin-top: 5px;
    right: 16px;
    color: #555;
}

.prompt-wrapper {
    padding: 5px 10px;
    border-radius: 4px    
}

.prompt-wrapper .option-button {
    min-width: 80px;
}

.prompt-wrapper .response-note {
    font-size: 11px;
    margin-top: 5px;
}

.inline-question-response .value {
    font-weight: 500;
    color: #000000;
}


.guest-inbox-app__message-list-item.patient .conversation-message-cta__bubble {
    background-color: #e8f0fe;
}

.assessment-cta-preprompt, .assessment-cta-response {
    font-size: 14px;
    margin-bottom: 10px;
    word-break: break-word;
    white-space: pre-line;
}



.conversation-message-cta__content {
    display: flex;
}

.conversation-message-cta__text {
    flex: 1;
    padding: 0 10px;
}

.conversation-message-cta__text h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 4px;
}

.conversation-message-cta__text p {
    font-size: 12px;
    line-height: 1.1;
}

.guest-inbox-app__message-list-item__cta-wrapper {
    display: flex;
    margin-top: 16px;
}

.guest-inbox-app__message-list-item.patient .guest-inbox-app__message-list-item__cta-wrapper {
    justify-content: flex-end;
}