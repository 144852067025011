
.guest-inbox-app__conversation-header {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    height: 100%;
}

.guest-inbox-app__conversation-list-wrap {
    flex-grow: 1;
}

.MuiToolbar-root {
    border-bottom: 1px solid #e6e6e6;
    background: #fff;
}

.conversation-list-item .MuiListItemText-root {
    padding: 0px 0px 0px 10px;
    color: #444;
}

.guest-inbox-app__conversation-list {
    position: relative;
    height: 100%;
    overflow: auto;
}

.conversation-list-item {
    border-bottom: 1px solid #eee !important;
} 

.conversation-list-item__primary {
    display: flex;
    align-items: center;
}

.conversation-list-item__primary-subject {
    flex-grow: 1;
}

.conversation-list-item .conversation-list-item__primary-date {
    font-size: 10px;
}

.conversation-list-item .conversation-list-item__secondary-text {
    font-size: 12px;
    font-weight: 400;
    color: #9b9b9b;
    display: block;
}

.conversation-list-item.unread .MuiListItemText-root {
    color: #000;
}

.conversation-list-item.unread .conversation-list-item__primary-subject {
    font-weight: 500;
}

.conversation-list-item.unread .conversation-list-item__primary-date {
    font-weight: 600;
}

.conversation-list-item.unread .conversation-list-item__secondary-text {

}

.inbox-activity-avatar-badge {
    padding: 0;
    height: 12px !important;
    min-width: 12px !important;
    right: 6px !important;
    top: 6px !important;
    border-radius: 10px !important;
    background-color: #E87A80 !important;
}